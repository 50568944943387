import PropTypes from "prop-types";
import {useState} from "react";
import useCustomEventListener from "@/hooks/useCustomEventListener";
import useSubscriptionTag from "@/hooks/useSubscriptionTag";
import {formatMoney} from "@shopify/theme-currency";
import {useTranslation} from "react-i18next";

const ProductCardPrice = ({
  defaultVariantId,
  defaultSellingPlanId,
  prices,
  sectionId,
  enableTag = false,
}) => {
  const {t} = useTranslation();
  const [wasOpened, setWasOpened] = useState(false);
  const [id, setId] = useState(defaultVariantId);
  const [quantity, setQuantity] = useState(1);
  const [sellingPlanId, setSellingPlanId] = useState(defaultSellingPlanId);

  const handleVariantChange = payload => {
    setId(payload.variantId);
    setQuantity(payload.quantity);
    setSellingPlanId(payload.selling_plan);
  };

  const handleOpenChange = () => {
    setWasOpened(true);
  };
  const handleCloseChange = () => {
    setWasOpened(false);
  };

  const getPrice = (id, quantity, sellingPlanId) =>
    prices.find(
      priceData =>
        priceData.id === id &&
        priceData.quantity === quantity &&
        priceData.selling_plan === sellingPlanId,
    ) ??
    prices.find(
      priceData =>
        priceData.id === id && priceData.selling_plan === sellingPlanId,
    ) ??
    prices.find(
      priceData => priceData.id === id && priceData.quantity === quantity,
    ) ??
    prices.find(priceData => priceData.id === id) ??
    prices[0];

  const selectedPrice = getPrice(id, quantity, sellingPlanId);

  const {subscriptionTagLabel} = useSubscriptionTag(selectedPrice);

  useCustomEventListener(
    `product-form:change:${defaultVariantId}-${sectionId}`,
    handleVariantChange,
  );

  useCustomEventListener(
    `product-form:open:${defaultVariantId}-${sectionId}`,
    handleOpenChange,
  );
  useCustomEventListener(
    `product-form:close:${defaultVariantId}-${sectionId}`,
    handleCloseChange,
  );

  return (
    <div className="product-card-price flex items-center gap-1 mt-[2px]">
      <span className="font-dm-sans text-lg font-normal not-italic leading-[140%] tracking-[0.00875rem] text-deep-green md:tracking-normal">
        {formatMoney(selectedPrice.price)}
      </span>
      {!wasOpened ? (
        <></>
      ) : (
        selectedPrice.compareAtPrice && (
          <span className="font-dm-sans text-lg font-normal not-italic leading-[140%] tracking-[0.00875rem] text-deep-green line-through decoration-1 opacity-50 md:tracking-normal">
            {formatMoney(selectedPrice.compareAtPrice)}
          </span>
        )
      )}
      {enableTag && subscriptionTagLabel && (
        <span className="tag tag--secondary relative flex whitespace-nowrap px-[0.38rem] py-1">
          {t("product_card.subscription_tag", {
            percentage: subscriptionTagLabel,
          })}
        </span>
      )}
    </div>
  );
};

ProductCardPrice.propTypes = {
  sectionId: PropTypes.string,
  defaultVariantId: PropTypes.number,
  defaultSellingPlanId: PropTypes.number,
  prices: PropTypes.array,
  enableTag: PropTypes.bool,
};

export default ProductCardPrice;
