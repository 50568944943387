import {PropTypes} from "prop-types";
import HTMLParser from "../HTMLParser";

const Richtext = ({content}) => (
  <HTMLParser className="richtext" html={content} />
);

Richtext.propTypes = {
  content: PropTypes.string,
};

export default Richtext;
