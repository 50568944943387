import PropTypes from "prop-types";
import {cn} from "@/utils/cn";
import {createRef} from "react";
import {useTranslation} from "react-i18next";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {formatMoney} from "@shopify/theme-currency";
import useProductForm from "@/hooks/useProductForm";
import SubscriptionRadio from "../SubscriptionRadio";

const FeaturedProductForm = ({
  layout,
  product,
  sectionId,
  defaultVariantId,
  defaultSellingPlanId,
}) => {
  const {t} = useTranslation();

  const bottomLayerRef = createRef(null);
  const formRef = createRef(null);
  const {
    id,
    quantity,
    hasSubscription,
    isActive,
    setSubscriptionActive,
    loading,
    addToCart,
    currentPrice,
  } = useProductForm({
    product,
    defaultVariantId,
    defaultSellingPlanId,
    formId: sectionId,
  });

  const addToCartLabel = `${t("action.add_to_cart")}${layout !== "secondary" ? " • " : ""}`;

  return (
    <form ref={formRef} className="pt-2 md:min-h-[190px] md:pt-8">
      {hasSubscription ? (
        <div className="flex flex-col px-0">
          <input
            className="sr-only"
            type="radio"
            name="id"
            value={id}
            readOnly
            aria-label="Product ID"
          />
          <input
            className="sr-only"
            id={`quantity-${product.id}-${sectionId}`}
            type="number"
            value={quantity}
            readOnly
            aria-label="Quantity"
          />

          <SwitchTransition>
            <CSSTransition
              in={true}
              nodeRef={bottomLayerRef}
              timeout={150}
              classNames="fade"
              key={isActive}
            >
              <div className="flex flex-col gap-4">
                {hasSubscription && (
                  <div
                    className={cn(
                      "top-layer flex",
                      layout === "secondary"
                        ? "flex-col gap-1 md:flex-row md:justify-center md:gap-8"
                        : "flex-col",
                    )}
                  >
                    <SubscriptionRadio
                      id={`otp-${product.id}`}
                      name="subscription-selector"
                      checked={isActive}
                      onValueChange={() => setSubscriptionActive(true)}
                      label={t("product_card.option_sub")}
                      layout={layout}
                    />
                    <SubscriptionRadio
                      id={`otp-${product.id}`}
                      name="subscription-selector"
                      checked={!isActive}
                      onValueChange={() => setSubscriptionActive(false)}
                      label={t("product_card.option_otp")}
                      layout={layout}
                    />
                  </div>
                )}

                <div
                  ref={bottomLayerRef}
                  className={cn(
                    "flex flex-col gap-4",
                    layout === "secondary" ? "" : "md:flex-col xl:flex-row",
                  )}
                >
                  <button
                    className={cn(
                      "button button--primary flex-1",
                      loading && "disabled",
                      layout === "secondary" && "mb-6",
                    )}
                    onClick={e => {
                      e.preventDefault();
                      addToCart();
                    }}
                    disabled={loading}
                  >
                    {addToCartLabel}
                    {layout === "secondary" ? (
                      <></>
                    ) : (
                      <>
                        <span className="mr-1 font-dm-sans text-base font-normal not-italic leading-[140%]">
                          {formatMoney(currentPrice.price)}
                        </span>
                        {currentPrice.compareAtPrice && (
                          <span className="font-dm-sans text-base font-normal not-italic leading-[140%] line-through decoration-1">
                            {formatMoney(currentPrice.compareAtPrice)}
                          </span>
                        )}
                      </>
                    )}
                  </button>
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      ) : (
        <button
          className={cn(
            "button button--primary w-full flex-1",
            loading && "disabled",
            layout === "secondary" && "mb-6",
          )}
          onClick={e => {
            e.preventDefault();
            addToCart();
          }}
          disabled={loading}
        >
          {addToCartLabel}
          {layout === "secondary" ? (
            <></>
          ) : (
            <>
              <span className="mr-1 font-dm-sans text-base font-normal not-italic leading-[140%]">
                {formatMoney(currentPrice.price)}
              </span>
              {currentPrice.compareAtPrice && (
                <span className="font-dm-sans text-base font-normal not-italic leading-[140%] line-through decoration-1">
                  {formatMoney(currentPrice.compareAtPrice)}
                </span>
              )}
            </>
          )}
        </button>
      )}
    </form>
  );
};

FeaturedProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
  defaultVariantId: PropTypes.number,
  defaultSellingPlanId: PropTypes.number,
  prices: PropTypes.array,
  layout: PropTypes.string,
};

export default FeaturedProductForm;
