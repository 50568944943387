import {useRef, useState} from "react";
import {PropTypes} from "prop-types";
import useCustomEventListener from "@/hooks/useCustomEventListener";
import HTMLParser from "../HTMLParser";
import {CSSTransition, SwitchTransition} from "react-transition-group";

/**
 *
 * @param {Object} templates - Templates that will be displayed
 *
 */
const TemplateSwitch = ({templates, transitionClass = "slide-left"}) => {
  const ref = useRef();
  const [activeTemplateKey, setActiveTemplate] = useState(
    Object.keys(templates)[0],
  );

  useCustomEventListener("template-switch:change", ({key}) => {
    setActiveTemplate(key);
  });

  return (
    <SwitchTransition>
      <CSSTransition
        key={activeTemplateKey}
        nodeRef={ref}
        addEndListener={done =>
          ref.current.addEventListener("transitionend", done, false)
        }
        classNames={transitionClass}
      >
        <HTMLParser ref={ref} html={templates[activeTemplateKey]} />
      </CSSTransition>
    </SwitchTransition>
  );
};

TemplateSwitch.propTypes = {
  templates: PropTypes.object.isRequired,
  transitionClass: PropTypes.string,
};

export default TemplateSwitch;
