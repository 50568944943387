import PropTypes from "prop-types";
import {cn} from "@/utils/cn";
import {createRef} from "react";
import {useTranslation} from "react-i18next";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import useProductForm from "@/hooks/useProductForm";
import useSubscriptionTag from "@/hooks/useSubscriptionTag";
import SubscriptionRadio from "../SubscriptionRadio";

const ProductCardForm = ({product, sectionId}) => {
  const {t} = useTranslation();

  const {
    id,
    quantity,
    hasSubscription,
    isActive,
    setSubscriptionActive,
    loading,
    addToCart,
    currentPrice,
  } = useProductForm({product, formId: sectionId});

  const bottomLayerRef = createRef(null);

  const addToCartLabel = t("action.add_to_cart");

  const {subscriptionTagLabel} = useSubscriptionTag(currentPrice);

  return (
    <>
      {hasSubscription ? (
        <form>
          <div className="flex flex-col px-0">
            <input
              className="sr-only"
              type="radio"
              name="id"
              value={id}
              readOnly
              aria-label="Product ID"
            />
            <input
              className="sr-only"
              id={`quantity-${product.id}-${sectionId}`}
              type="number"
              value={quantity}
              readOnly
              aria-label="Quantity"
            />

            <SwitchTransition>
              <CSSTransition
                in={true}
                nodeRef={bottomLayerRef}
                timeout={150}
                classNames="fade"
                key={isActive}
              >
                <div className="flex flex-col gap-4">
                  {hasSubscription && (
                    <div className="top-layer flex flex-col">
                      <SubscriptionRadio
                        id={`otp-${product.id}`}
                        name="subscription-selector"
                        checked={isActive}
                        onValueChange={() => setSubscriptionActive(true)}
                        label={t("product_card.option_sub")}
                        {...(subscriptionTagLabel && {
                          tag: t("product_card.subscription_tag", {
                            percentage: subscriptionTagLabel,
                          }),
                        })}
                      />
                      <SubscriptionRadio
                        id={`otp-${product.id}`}
                        name="subscription-selector"
                        checked={!isActive}
                        onValueChange={() => setSubscriptionActive(false)}
                        label={t("product_card.option_otp")}
                      />
                    </div>
                  )}
                  <button
                    className={cn(
                      "button button--primary",
                      loading && "disabled",
                    )}
                    onClick={e => {
                      e.preventDefault();
                      addToCart();
                    }}
                    disabled={loading}
                  >
                    {addToCartLabel}
                  </button>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </form>
      ) : (
        <button
          className={cn("button button--primary w-full", loading && "disabled")}
          onClick={e => {
            e.preventDefault();
            addToCart();
          }}
          disabled={loading}
        >
          {addToCartLabel}
        </button>
      )}
    </>
  );
};

ProductCardForm.propTypes = {
  product: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
};

export default ProductCardForm;
