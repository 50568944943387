export default currentPriceData => {
  const subscriptionTagPercentage =
    currentPriceData &&
    100 -
      Math.round(
        (100 * currentPriceData.price) / currentPriceData.compareAtPrice,
      );

  const subscriptionTagLabel =
    !subscriptionTagPercentage || subscriptionTagPercentage < 0
      ? undefined
      : subscriptionTagPercentage;

  return {
    subscriptionTagLabel,
    subscriptionTagPercentage,
  };
};
