import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ProductCardForm from "../ProductCardForm";
import ProductCardPrice from "../ProductCardPrice";

const ProductSlider = ({ slides, limit, sectionId }) => {
  return (
    <Swiper
      className="product-slider"
      spaceBetween={50}
      slidesPerView={4}
      breakpoints={{
        0: {
          enabled: false,
        },
        1024: {
          enabled: true,
        },
      }}
    >
      {slides.map((props, i) => (
        <SwiperSlide key={i} className={i >= limit ? "!hidden md:!block" : ""}>
          <ProductSlide {...props} sectionId={sectionId} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

ProductSlider.propTypes = {
  slides: PropTypes.array.isRequired,
  sectionId: PropTypes.string,
  limit: PropTypes.number,
};

const ProductSlide = ({
  defaultVariantId,
  defaultSellingPlanId,
  prices,
  sectionId,
  product,
  dataSku,
}) => {
  const { t } = useTranslation();

  const tag = product?.tags?.find(tag => tag.indexOf("badge:"));

  useEffect(() => {
    const trySetRatingStar = () => {
      if (typeof ratingSnippet === "function") {
        window.ratingSnippet("ruk_rating_snippet", {
          store: window.Theme.reviewsio_store_id,
          mode: "default",
          color: "#0B2E24",
          linebreak: false,
          usePolaris: true,
          showEmptyStars: false,
          text: "Reviews",
          singularText: "Reviews",
        });
      } else {
        setTimeout(() => {
          trySetRatingStar();
        }, 500);
      }
    };
    trySetRatingStar();
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <a
        href={product.url}
        aria-label={product.title}
        className="relative block aspect-square w-full overflow-hidden rounded-3xl"
      >
        {product.featured_image && (
          <img
            sizes="(min-width: 768px) calc(50vw - 2rem), (min-width: 1024px) calc(25vw - 2rem), calc(100vw - 2rem)"
            srcSet={product.featured_image.srcset}
            src={product.featured_image.src}
            alt={product.featured_image.alt}
            loading="lazy"
            className="image object-cover transition-opacity hover:opacity-70"
          />
        )}

        {tag && (
          <span
            key={tag}
            className="absolute left-3 top-4 rounded-full bg-deep-green px-[0.75rem] py-[0.38rem] font-dm-sans text-xs font-medium capitalize not-italic leading-[1] text-white"
          >
            {tag?.replace("_", " ").replace("badge:", "")}
          </span>
        )}
      </a>

      <div className="">
        <div className="flex w-full items-start justify-between">
          <a href={product.url} className="h6 text-lg text-deep-green">
            {product.title}
          </a>

          {product.available ? (
            <ProductCardPrice
              sectionId={sectionId}
              prices={prices}
              defaultVariantId={defaultVariantId}
              defaultSellingPlanId={defaultSellingPlanId}
            />
          ) : (
            <div>{t("common.sold_out")}</div>
          )}
        </div>

        <div aria-label={product.title} className="ruk_rating_snippet stars" data-sku={dataSku}></div>

        <div className="mt-2">
          <ProductCardForm product={product} sectionId={sectionId} />
        </div>
      </div>
    </div>
  );
};

ProductSlide.propTypes = {
  sectionId: PropTypes.string,
  dataSku: PropTypes.string,
  defaultVariantId: PropTypes.number,
  defaultSellingPlanId: PropTypes.number,
  prices: PropTypes.array,
  product: PropTypes.object,
};

export default ProductSlider;
