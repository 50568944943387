import {cn} from "@/utils/cn";
import PropTypes from "prop-types";

const SubscriptionRadio = ({
  name,
  checked,
  onValueChange,
  label,
  layout,
  tag,
}) => (
  <label
    className={cn(
      "subscription-radio relative flex cursor-pointer items-center gap-2 transition-opacity",
      !checked && "hover:opacity-70",
    )}
  >
    <input
      type="radio"
      className="sr-only focus:[&+div]:border focus:[&+div]:border-light-yellow"
      name={name}
      checked={checked}
      onChange={onValueChange}
    />
    <div
      className={cn(
        "flex flex-shrink-0 h-4 w-4 items-center justify-center rounded-full border border-dark-green",
      )}
    >
      <svg
        className={cn(
          "transition-opacity",
          checked ? "opacity-100" : "opacity-0",
        )}
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
      >
        <circle cx="4" cy="4" r="3.5" fill="#104234" stroke="#104234" />
      </svg>
    </div>
    <span
      className={cn(
        "font-dm-sans text-base font-normal not-italic leading-[140%] ",
        layout === "secondary"
          ? "translate-y-[0.5px] text-deep-green"
          : "text-dark-green",
      )}
    >
      {label}
    </span>
    {tag && (
      <span className="tag tag--secondary relative right-0 flex whitespace-nowrap px-[0.38rem] py-1">
        {tag}
      </span>
    )}
  </label>
);

SubscriptionRadio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  layout: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onValueChange: PropTypes.func,
  tag: PropTypes.string,
};

export default SubscriptionRadio;
