// This file is imported in all theme.liquid pages (most of Shopify pages uses theme.liquid).

import {
  renderStructuredElements,
  reRenderStructuredElements,
} from "@/utils/render-helpers";
import HeaderMenu from "@/components/HeaderMenu";
import TemplateSwitch from "@/components/TemplateSwitch";
import ProductSlider from "@/components/ProductSlider";
import ProductCardForm from "@/components/ProductCardForm";
import ProductCardPrice from "@/components/ProductCardPrice";
import FeaturedProductForm from "@/components/FeaturedProductForm";
import Richtext from "@/components/Richtext";

// Translation entrypoint
import "@/utils/i18n";

const render = () => {
  renderStructuredElements("header-menu", HeaderMenu);
  renderStructuredElements("richtext", Richtext);
  renderStructuredElements("template-switch", TemplateSwitch);
  renderStructuredElements("product-slider", ProductSlider);
  renderStructuredElements("product-card-form", ProductCardForm);
  renderStructuredElements("product-card-price", ProductCardPrice);
  renderStructuredElements("featured-product-form", FeaturedProductForm);
};
const reRender = () => {
  reRenderStructuredElements("header-menu", HeaderMenu);
  reRenderStructuredElements("richtext", Richtext);
  reRenderStructuredElements("template-switch", TemplateSwitch);
  reRenderStructuredElements("product-slider", ProductSlider);
  reRenderStructuredElements("product-card-form", ProductCardForm);
  reRenderStructuredElements("product-card-price", ProductCardPrice);
  reRenderStructuredElements("featured-product-form", FeaturedProductForm);
};

document.addEventListener("react:reload", reRender);
document.addEventListener("DOMContentLoaded", render);
